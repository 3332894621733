/*global prodcat*/
(function(site, $) {
  var formCaptureObj = {}, linkCaptureObj = {};
  var drupalAltImgSettings = Drupal.settings.analytics ? Drupal.settings.analytics.alt_image_tagging_enabled : false;

  Drupal.behaviors.analyticsBehavior = {

    attached: 0,

    findElementIndex: function(arr, value) {
      return _.findIndex(arr, function(elem) {
        return elem === value;
      });
    },

    linkToPage: function() {
      window.open(linkCaptureObj.href, linkCaptureObj.target);
    },

    setLinkCapture: function(href, target) {
      linkCaptureObj.href = href;
      linkCaptureObj.target = target;
    },

    submitForm: function() {
      formCaptureObj.form.off('submit');
      formCaptureObj.form.trigger('submit');
    },

    stripOutMarkup: function(str) {
      return str.replace(/(<([^>]+)>)/ig, '');
    },

    // Accepts an array of PRODUCT_IDS, returns an array of positions
    getProductPositions: function(productIds) {
      var positions = [];
      var i;
      var j = productIds.length;
      if (window.hasOwnProperty('prodcat') && prodcat.hasOwnProperty('data') && prodcat.data.hasOwnProperty('pids')) {
        for (i = 0; i < j; i++) {
          positions.push(this.findElementIndex(prodcat.data.pids, productIds[i]));
        }
      }
      return positions;
    },

    // Examples of brand specific overrides for event handling

    addToCart: function(eventData) {
      site.track.addToCart(Object.assign({}, eventData));
    },

    addToFavorites: function(eventData) {
      site.track.addToFavorites(Object.assign({}, eventData));
    },

    removeFromCart: function(eventData) {
      site.track.removeFromCart(Object.assign({}, eventData));
    },

    // End examples brand specific overrides for event handling

    attach: function(context) {
      // all code here
      var self = this;
      if (self.attached) {
        return;
      }

      // Track Brand Logo
      $('.site-header__logo__container a', context).on('click', function(event) {
        var obj;
        event.preventDefault();
        self.setLinkCapture($(this).attr('href'), '_self');
        obj = {
          event_name: 'logo_click',
          event_category: 'global',
          event_action: 'logo clicked'
        };
        site.track.evtLink(obj, self.linkToPage);
      });

      // Track Notify me button
      $('.js-add-to-waitlist').on('click', function() {
        var $targetElem = $(this).closest('.js-product');
        var prodId = $targetElem.data('product-id');
        var prodName = $targetElem.find('h1.product-full__name:first').text().trim() || $targetElem.find('.product-brief__name a').text().trim();
        trackEvent('notify_me', 'ecommerce', 'notify me - request', prodName + ' - ' + prodId);
      });

      // Track Product Click
      $(document).on('click', '.js-product-brief a.js-spp-link, .js-product-grid-item .esearch-product a.js-spp-link', function () {
        var $currentElem = $(this);
        var prodElem = $(this).closest('.js-product');
        var prodId = prodElem.attr('data-product-id');
        var prodName = prodElem.find('.product-brief__name').text().trim();

        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $currentElem,
            product_id: [prodId],
            product_impression_name: [prodName]
          });
        }
      });

      // Product click on Recommendation section
      $('.js-recommended-products-panel .js-grid-item, .js-recommended-products-panel .js-recommended-item').each(function(index) {
        $(this).attr('data-index', index);
      });
      recomProductSelectors = [
        '.recommended-item a.thumb',
        '.recommended-item .product-name a',
        '.js-recommended-products-panel .recommended-items a.thumb',
        '.js-recommended-products-panel .product_name a'
      ];
      $(document).on('click', recomProductSelectors.join(', '), function () {
        var $prodElem = $(this);
        var $prodHref = $prodElem.attr('href');
        var splitprod = $prodHref.split('/');
        var prodId = 'PROD' + splitprod[3];
        var prodPosition = Number($prodElem.closest('.recommended-items, .recommended-item').data('index')) + 1;
        var prodName = $prodElem.closest('.recommended-items, .recommended-item').find('.product_name a, .product-name a').text().trim();
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $prodElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name:[prodName]
          });
        }
      });

      // Track Quick Shop
      $('body').on('click', '.js-quickshop-launch-overlay', function() {
        var obj;
        var $targetElement = $(this);
        var catName = $('.content > article', context).attr('trackname');
        var prodElem = $targetElement.closest('.js-product-brief');
        var prodId = $targetElement.attr('data-product-id');
        var prodBaseId = prodId.replace(/PROD/g, '');
        var prodName = $.trim(self.stripOutMarkup(prodElem.find('.product-brief__footer').find('.product-brief__name a').html()));
        obj = {
          event_label: prodName + ' - ' + $targetElement.attr('data-product-id'),
          page_name: 'QV | ' + prodName,
          product_id: [prodId],
          product_base_id: [prodBaseId],
          product_catagory_name: [catName],
          product_price: [prodElem.find('.js-product-price').text().replace(/\s+/g, ' ').trim()],
          product_name: [prodName]
        };
        site.track.quickView(obj);
      });

      // Track Predictive Search Product Click and All Results Click
      $(window).on('endeca.typeahead.complete', function() {
        var prods, term;
        prods = $('.js-typeahead-wrapper a');
        term = $('.esearch-nav__form-fields input#search.js-search-term').val();
        prods.on('click', function() {
          var $prod = $(this);
          var $prodWrapper = $prod.closest('.js-product-search-brief');
          var obj;
          self.setLinkCapture($(this).attr('href'), '_self');
          obj = {
            event_label: term,
            search_keyword: term,
            product_sku: 'SKU' + $prodWrapper.attr('data-sku-base-id'),
            product_id: $prodWrapper.attr('data-product-id'),
            product_name: $prod.closest('.product-brief__container').find('.js-product-name').text().trim()
          };
          site.track.evtAction('searchOneResultSelected', obj, self.linkToPage);
        });
      });

      // Track Hair-Quiz-Facebook Pixel
      $('.js-quiz-mark-target').on('click', function() {
        var getType = $(this).parent().attr('data-quiz-answer');
        var obj;
        if (getType === undefined) {
          getType = $(this).attr('data-quiz-answer');
        }

        obj = {
          event_name: 'hair_quiz',
          hair_quiz_answer: getType.toLowerCase().replace(/_/g, ' ').trim()
        };
        site.track.evtLink(obj);
      });

      // Track Hair-Quiz-Complete Facebook Pixel
      $('.js-quiz-submit').on('click', function() {
        var obj;
        obj = {
          event_name: 'hair_quiz_complete',
          hair_quiz_answer: 'CompletedRegistration'
        };
        site.track.evtLink(obj);
      });

      // Track MPP Filters
      $(document).on('click', '.js-mpp-filter-set__button', function() {
        var obj, elem;
        elem = $(this);
        obj = {
          event_label: elem.text().trim()
        };
        site.track.evtAction('filterProducts', obj);
      });

      // CHECKOUT EVENTS

      // Track guest user checkout
      $('#checkout_signin_guest_user .form-submit').on('click', function () {
        var obj = {};

        site.track.evtAction('checkoutGuestUser', obj);
      });

      // Track return user checkout
      $('#checkout_signin .form-submit').on('click', function () {
        var obj = {};

        site.track.evtAction('checkoutReturnUser', obj);
      });

      // END CHECKOUT EVENTS

      // Track Payment Method
      $(document).on('checkout:panel:displayed', function() {
        $('#checkout_billing input.js-submit-payment, #checkout_review input.js-submit-payment').on('click', function() {
          var payment_label = '';
          var payment_type = $('input[name=PAYMENT_METHOD]:checked, input[name=PAYMENT_TYPE]:checked').val();
          if (payment_type === 'PP' || payment_type === 'PayPal') {
            payment_label = 'paypal';
          } else if (payment_type === 'CC' || payment_type === 'Credit Card') {
            payment_label = 'creditcard';
          }
          var obj = {
            event_label: payment_label
          };
          site.track.evtAction('checkoutPaymentSelected', obj);
        });
      });

      // Extole Refer A friend    
      $(document).on('submit', '.extole-js-email-panel-email-share-form', function() {
        var userEmail = '';
        var name = '';
        var referEmail = '';
        var obj = '';
        var prod = $(this);
        userEmail = prod.find('.extole-input .extole-tokenizer-token').first().text();
        name = prod.find('.extole-js-user-first-name').val();
        referEmail = prod.find('.extole-recipient-emails .extole-tokenizer-token').text();
        if (userEmail && name && referEmail) {
          obj = {
            'event_category': 'Refer_friend',
            'event_action': 'Refer triggered',
            'event_label': 'Refer_Email' + ' - ' + document.location.href,
          };
          site.track.evtLink(obj);
        }
      });

      (function($) {
        var extoleConfirm = [
          '.extole-js-facebook-panel-capture-email-form',
          '.extole-js-twitter-panel-capture-email-form',
          '.extole-js-messenger-panel-capture-email-form'
        ];
        $(document).on('submit', extoleConfirm.join(', '), function() {
          var token = '';
          var obj = '';
          var prod = $(this);
          var referMethod = '';
          referMethod = $('.extole-panel-link--active').attr('class').split(' ')[2].split('-')[1];
          token = prod.find('.extole-tokenizer-token').text();
          if (token) {
            obj = {
              'event_category': 'Refer_friend',
              'event_action': 'Refer triggered',
              'event_label': 'Refer_' + referMethod + ' - ' + document.location.href,
            };
            site.track.evtLink(obj);
          }
        });
      }(jQuery));

      (function($) {
        var extoleDefault = [
          '.extole-js-twitter-panel-share-form',
          '.extole-js-facebook-panel-share-form',
          '.extole-js-messenger-panel-share-form'
        ];
        $(document).on('submit', extoleDefault.join(', '), function() {
          var obj = '';
          var referMethod = '';
          var token = '';
          referMethod = $('.extole-panel-link--active').attr('class').split(' ')[2].split('-')[1];
          token = $('.extole-js-email-panel-email-share-form').find('.extole-tokenizer-token').text();
          if (token) {
            obj = {
              'event_category': 'Refer_friend',
              'event_action': 'Refer triggered',
              'event_label': 'Refer_' + referMethod + ' - ' + document.location.href,
            };
            site.track.evtLink(obj);
          }
        });
      }(jQuery));

      //Tagging for Bumble quiz start.
      if ($('body').hasClass('section-hair-quiz')) {
        trackHairquizTagging();
      }

      function trackHairquizTagging() {
        var objView = {};
        initHairQuizTagging();

        function startQuizTagging() {
          $('.js-quiz-launch, .hair-quiz__results-redo, .hair-quiz__results-redo-quiz', context).once().on('click', function() {
            var eventLabel = $(this).hasClass('hair-quiz__results-redo') || $(this).hasClass('hair-quiz__results-redo-quiz') ? 'Redo quiz' : 'start';
            trackEvent('bumbleQuiz_Start', 'diagnostic tool - hair quiz', eventLabel, 'click');
            var emailCheck = $('div').find('.js-gnav-util__account.hidden').length;
            var reQuizStep = emailCheck === 1 ? '3' : '2';
            trackQuizPage('/hair-quiz/step' + reQuizStep, '/hair-quiz/step' + reQuizStep, 'Hair Quiz - Step ' + reQuizStep);
          });
        }

        function hairTypeCountinueTagging() {
          $(document).on('Continue', function(event, data) {
            var stepCount = data.stepNumber + 1;
            var currentQuestion = data.currentQuestion;
            var replaceVariable = currentQuestion + '_';
            var eventLabel;
            var quizAnswer = data.quizAnswer.toString().replace(new RegExp(replaceVariable, 'gi'), '').trim();
            if (currentQuestion === 'user_email') {
              var $emailCheck = $('.js-hair-email-signup').val() === '';
              eventLabel = $emailCheck ? 'No' : 'Yes';
              trackEvent('bbQuiz_step' + stepCount, 'diagnostic tool - hair quiz', 'save results to email', eventLabel);
            } else {
              trackEvent('bbQuiz_step' + stepCount, 'diagnostic tool - hair quiz', 'questions - ' + currentQuestion, quizAnswer);
            }
            trackQuizPage('/hair-quiz/step' + stepCount, '/hair-quiz/step' + stepCount, 'Hair Quiz - Step ' + stepCount);
          });
        }

        function quizPreviousTagging() {
          $(document).on('hairpreview', function(event, data) {
            var backStepCount = data.hairpreviewStep + 1;
            trackEvent('bumbleQuiz_back', 'diagnostic tool - hair quiz', 'back button', 'click');
            trackQuizPage('/hair-quiz/step' + backStepCount, '/hair-quiz/step' + backStepCount, 'Hair Quiz - Step ' + backStepCount);
          });
        }

        function trackquizAnswer() {
          $(document).on('Submit', function(event, data) {
            var currentQuestion = $('.hair-quiz__question--desiredhairstyle').data('quiz-question');
            var quizAnswer = $('.hair-quiz__question--desiredhairstyle').find('.hair-quiz__illustration.is-selected .hair-quiz__illustration-text').text().trim();
            trackEvent('bbQuiz_step8', 'diagnostic tool - hair quiz', 'questions - ' + currentQuestion, quizAnswer);
          });
        }

        function resultPageTagging() {
          $(document).on('Result', function(event, data) {
            var $menuItems = $('.hair-quiz__results-content');
            var productImpressionIds = [];
            var productPositions = [];
            var productImpressionList = [];
            var $elem;
            $menuItems.find('.js-product-brief').each(function(index, elem) {
              if (!$(elem).length) {
                return;
              }
              $elem = $(elem);
              productImpressionIds.push($elem.attr('data-product-id'));
              productPositions.push(index + 1);
              productImpressionList.push(location.pathname + '/complete');
              $(this).attr('data-index', index + 1);
            });

            var objView = {
              product_impression_list: productImpressionList,
              product_impression_position: productPositions,
              product_impression_id: productImpressionIds,
              location: location.origin + location.pathname + '/complete',
              page_url: '/hair-quiz/complete',
              page_name: 'Hair Quiz - Complete'
            };
            site.track.evtView(objView);
            $('.js-quiz-results-set-products').find('.js-product-brief').each(function(index, elem) {
              $(this).attr('data-index', index + 1);
            });
          });
        }

        function trackProductResult() {
          $(document).on('click', '.js-product-brief', function() {
            var quizProductId = $(this).data('product-id');
            var quizProductPosition = $(this).data('index');
            var eventObj = {};
            eventObj.enh_action = 'product_click';
            eventObj.product_position = [quizProductPosition];
            eventObj.product_list = ['/hair-quiz/complete'];
            eventObj.product_id = [quizProductId];
            trackEvent('bumbleQuiz_productClick', 'diagnostic tool - hair quiz', 'product click', 'product click', eventObj);
          });
        }

        function trackAddtoBag() {
          $(document).on('click', '.js-add-to-cart', function() {
            var productIds = [];
            var productPositions = [];
            var eventObj = {};
            $(this).closest('.hair-quiz__results-set').find('.js-product-brief').each(function(index, elem) {
              productIds.push($(this).data('product-id'));
              productPositions.push($(this).data('index'));
            });

            eventObj.product_id = [productIds];
            eventObj.product_position = [productPositions];
            eventObj.product_list = ['/hair-quiz/complete'];
            eventObj.enh_action = 'product_click';
            trackEvent('bumbleQuiz_productClick', 'diagnostic tool - hair quiz', 'product click', 'product click', eventObj);

            eventObj.product_id = [productIds];
            eventObj.product_position = [productPositions];
            eventObj.product_list = ['/hair-quiz/complete'];
            eventObj.enh_action = 'detail';
            trackEvent('bumbleQuiz_productDetail', 'diagnostic tool - hair quiz', 'product detail', 'product detail', eventObj);

            eventObj.product_id = productIds;
            eventObj.product_position = productPositions;
            eventObj.product_list = ['/hair-quiz/complete'];
            eventObj.enh_action = 'add';
            trackEvent('bumbleQuiz_productDetail', 'diagnostic tool - hair quiz', 'product add to cart', 'product add to cart', eventObj);
          });
        }

        function trackQuizPage(pageurl, plocation, pname) {
          Object.assign(objView, {
            page_url: pageurl,
            location: location.origin + plocation,
            page_name: pname
          });
          site.track.evtView(objView);
          objView = {};
        }

        function initHairQuizTagging() {
          startQuizTagging();
          hairTypeCountinueTagging();
          quizPreviousTagging();
          trackquizAnswer();
          resultPageTagging();
          trackProductResult();
          trackAddtoBag();
        }
      }
      //Tagging for Bumble quiz end.

      // Alt image tracking start
      if ($('body').hasClass('section-product') && drupalAltImgSettings) {
        trackAltImageTagging();
      }

      // Track Alt image swipe
      function trackAltImageTagging() {
        $(document).on('afterChange', '.product-full__image', function() {
          var $targetElem = $(this);
          var altImageName = '';
          var prodDetails = buildAltImageProductName($targetElem);
          var bgImageUrl = $targetElem.find('.slick-current img').attr('src');
          if (bgImageUrl) {
            altImageName = buildAltImageFileName(bgImageUrl);
          }
          trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'swipe');
        });
      }

      // Build Alt image product name
      function buildAltImageProductName(targetElem) {
        var prodElem = targetElem.closest('.js-product');
        var prodId = prodElem.attr('data-product-id');
        var prodName = prodElem.find('.product-full__header .product-full__name').text();
        return [prodName, prodId];
      }

      // Build Alt image file name
      function buildAltImageFileName(altImageUrl) {
        var altImageArray = altImageUrl.split('/');
        var altImageName = altImageArray[altImageArray.length - 1].split('.')[0];
        return altImageName;
      }

      // Track product thumbnail click event
      function trackThumbnailClickEvent(altImageName, prodName, prodId, eventType) {
        var obj = {
          'event_action': 'alt image - ' + altImageName + ' - ' + eventType,
          'event_label': prodName + ' - ' + prodId
        };
        site.track.productThumbnailClick(obj);
      }
      // Alt image tracking end

      // Event track common function call start.
      function trackEvent(eName, eCategory, eAction, elabel, eventObj) {
        eventObj = eventObj || {};
        Object.assign(eventObj, {
          event_name: eName,
          event_category: eCategory,
          event_action: eAction,
          event_label: elabel
        });
        site.track.evtLink(eventObj);
      }
      // Event track common function call end.

      // Module arrow clicks
      $(document).on('mousedown touchdown', '.js-trending-looks-block-arrow, .slick-next, .slick-prev', function () {
        var $currentElem = $(this);
        var $targetElem = $currentElem.closest('.js-trending-looks-block__wrapper');
        var carouselName = $targetElem.length ? $targetElem.find('.trending-looks-block__intro .trending-looks-block__intro-title').text().trim() : '';
        var arrowClickType = $currentElem.hasClass('slick-next') ? 'right arrow click' : 'left arrow click';

        if ($currentElem.attr('class').indexOf('disabled') === -1 && !$currentElem.hasClass('js-trending-looks-block-item-arrow') && carouselName) {
          trackEvent('arrow_click', 'look_carousel', arrowClickType, carouselName);
        }
      });

      // Module details arrow clicks
      $(document).on('ModuleArrowClick', '.js-trending-looks-block-item-arrow', function () {
        var $currentElem = $(this);
        var carouselName = $currentElem.closest('.js-trending-looks-block-item-template-selector-info').find('.trending-looks-block-item-info__full-title').text().trim();
        var arrowClickType = $currentElem.hasClass('slick-next') ? 'right arrow click' : 'left arrow click';

        if ($currentElem.attr('class').indexOf('disabled') === -1 && carouselName) {
          trackEvent('arrow_click', 'look_carousel', arrowClickType, carouselName);
        }
      });

      // Track Product Click
      $(document).on('click', '.js-product-brief a.js-product__link-to-spp', function () {
        var $itemBlock = $(this).closest('.trending-looks-block-item-step');
        var $skuInfo = $itemBlock.find('.js-sku-price');
        var skuId = $skuInfo.attr('data-sku-id');
        var prodId = $skuInfo.attr('data-product-id');
        var skuPrice = $skuInfo.text().trim();
        var prodName = $itemBlock.find('.product-brief__product-name').text().trim();
        var obj = {};

        if (typeof prodId !== 'undefined' && prodId !== '') {
          obj = {
            event_name: 'product_click',
            event_category: 'look_carousel',
            event_action: 'product clicked',
            event_label: prodId + ' - ' + prodName,
            product_sku: skuId,
            product_price: skuPrice
          };
          site.track.evtLink(obj);
        }
      });
      self.attached = 1;
    }
  };
}(window.site || {}, jQuery));
